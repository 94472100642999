@import '../../utils';

$meeting-grey: #444;
$meeting-red: #c60c30;
$meeting-confirm: #11A24A;
$meeting-message: #e7d7d7;


.course-play-loader {
  width: calc(100vw - 40px);
  height: calc(100vh - 200px);
  border-top: solid $avg-grey 1px;
  margin: 0 auto;
}



@keyframes btnAnimation {
  from {
    background-color: #c60c30;
    color: white;
  }

  to {
    background-color: white;
    color: black;
  }
}

@keyframes iconAnimation {
  from {
    color: white;
  }

  to {
    color: black;
  }

  7% {
    transform: rotateZ(0);
  }

  15% {
    transform: rotateZ(-15deg);
  }

  20% {
    transform: rotateZ(10deg);
  }

  25% {
    transform: rotateZ(-10deg);
  }

  30% {
    transform: rotateZ(6deg);
  }

  35% {
    transform: rotateZ(-4deg);
  }

  40%,
  100% {
    transform: rotateZ(0);
  }
}

@keyframes zoom-in-zoom-out {
  0% {
    transform: scale(1.5, 1.5);
  }

  100% {
    transform: scale(1, 1);
  }
}

.floatBtnAnimation {
  animation: btnAnimation 1.5s infinite, zoom-in-zoom-out 5s;

  .iconAnimation {
    animation: iconAnimation 1.5s infinite;
  }
}

.standard-content {
  background-color: #f6ffed;
  border: 1px solid #52c41a;
}

.important-content {
  background-color: #e6f4ff;
  border: 1px solid #0958d9;
}

.urgent-content {
  background-color: #fff2f0;
  border: 1px solid #cf1322;
}

.floatBtn {
  position: fixed;
  bottom: 30px;
  right: 0;
  width: 40px;
  height: 40px;
  margin-right: 20px;
  margin-bottom: 50px;
  display: inline-flex;
  align-items: center;
  background-color: white;
  justify-content: center;
  border-radius: 50px;
  z-index: 900000;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  color: white;
  border: 'none';

  &:hover {
    cursor: pointer;
    background-color: #c60c30;
    color: white;

    .icon {
      color: white;
    }
  }

  .icon {
    width: 30px;
    height: 30px;
    padding: 5px;
    color: black;

    &:hover {
      color: white;
    }
  }
}

.course-play-page {
  width: 100vw;
  margin: 0 auto;
  padding: 0;
  overflow-x: hidden;

  h1 {
    text-align: center;
    padding: 0 0 15px 0;
    font-size: 25px;
  }

  .chatbot-box {
    position: fixed;
    bottom: 80px;
    right: 10px;
    z-index: 999;
  }

  .chatbot-icon-cnt {
    width: 60px;
    height: 60px;
    background-color: $intact-red;
    color: #fff;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    position: fixed;
    bottom: 10px;
    right: 10px;
    transition: all 0.25s linear;
    z-index: 999;
    box-shadow: -2px 1px 6px 3px rgba(75, 75, 75, 0.3);

    &:after {
      content: "";
      width: 15px;
      height: 15px;
      background-color: $intact-red;
      position: absolute;
      bottom: 3px;
      right: 3px;
      transition: all 0.25s linear;
    }

    &:hover {
      background-color: $intact-blue;
      cursor: pointer;

      &:after {
        background-color: $intact-blue;
      }
    }
  }

  .mobile-play-list-btn {
    position: absolute;
    top: 15px;
    left: 20px;
    font-size: 30px;
    display: none;

    &:hover {
      cursor: pointer;
    }

    @media #{$md-device} {
      display: block;

      &.fa-times {
        font-size: 35px;
        top: 15px;
      }
    }
  }

  .open-hidden-menu-btn {
    opacity: 0;
    pointer-events: none;
    position: absolute;
    top: 5px;
    left: -5px;
    width: 35px;
    height: 30px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    background-color: $intact-red;
    @include border-radius-separate(0, 5px, 0, 5px);

    svg {
      color: #fff;
      margin: 0 7px 0 0;
    }

    z-index: 99999;
    @include transition(all 0.25s linear);

    &.open-hidden-menu-btn-visible {
      opacity: 1;
      pointer-events: auto;

      &:hover {
        cursor: pointer;
      }
    }

    @media #{$md-device} {
      display: none;
    }
  }


  .play-container {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    height: 100vh;

    @media #{$md-device} {
      flex-direction: column;
      padding-top: 55px;
      height: auto;
      min-height: 100vh;
    }


    .play-content {
      width: calc(100vw - 350px);
      height: 100vh;
      overflow-y: auto;
      padding: 20px;
      @include box-sizing();
      @include transition(all 0.25s linear);

      @media #{$lg-device} {
        width: calc(100vw - 300px);
      }

      @media #{$md-device} {
        width: 100%;
        height: unset;
      }

      .keep-on-going {
        width: calc(100% + 1px);
        min-height: calc(100% + 1px);
        position: absolute;
        background-color: rgba(0, 0, 0, 0.9);
        @include translate(-50%, -50%);
        top: 50%;
        left: 50%;
        z-index: 996;
        padding: 150px 100px;

        @media #{$md-device} {
          background-color: rgba(0, 0, 0, 1);
          @include translate(0, 0);
          top: 0;
          left: 0;
          padding: 50px 25px;
          height: unset;
          min-height: 100vh;
        }

        .kog-wysiwig {
          padding: 0 0 25px;
          color: #fff;
        }

        button {
          background-color: $intact-red;
          outline: none;
          box-shadow: none;
          border: none;
          @include transition(all 0.25s linear);

          &:hover {
            @include border-radius-separate(15px, 5px, 5px, 5px);
            @include box-shadow(5px, 2px, 3px, 3px, rgba(68, 68, 68, 0.7));
          }

          &.replay-video-btn {
            margin: 0 15px 0 0;

            svg {
              font-size: 12px;
              margin: 0 0 0 10px;
            }
          }

          &.go-to-next-btn {
            svg {
              font-size: 18px;
              margin: 0 0 -2px 10px;
            }
          }
        }
      }
    }


    .play-content-intro {
      max-height: 100vh;
      overflow-y: auto;

      .intro-header {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        height: 350px;
        max-height: 25vh;
        overflow: hidden;
        @include border-radius(5px);

        .intro-header-bg {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
          }

          &:after {
            content: '';
            display: block;
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            background-color: rgba(0, 0, 0, 0.4);
          }

          &.no-header-banner-title {
            &:after {
              display: none;
            }
          }

          &.header-banner-image-custom {
            img {
              width: 100%;
              height: 100%;
              object-fit: contain;
              object-position: center;
            }

            left: 50%;
            @include translate(-50%, 0)
          }
        }

        .intro-label {
          position: relative;
          z-index: 99;
          color: #fff;
        }
      }

      .intro-text {
        background-color: rgba(255, 255, 255, 0.5);
        padding: 45px;
        margin: 25px 0 0 0;
        @include box-shadow(1px, 2px, 5px, 2px, rgba(82, 82, 82, 0.2));
      }

      .btn-group {

        margin-top: 25px;

        .btn-start,
        .btn-download-files {
          border: 0;
          border-radius: 5px;
          @include transition(all 0.25s linear);

          svg {
            margin: 0 10px 0 7px;
          }

          &:hover {
            @include box-shadow(5px, 3px, 5px, 3px, rgba(201, 201, 201, 1));
            @include border-radius-separate(15px, 5px, 5px, 5px);
          }
        }

        .btn-start {
          background-color: $intact-red;
          margin: 0 25px 0 0;
        }

        .btn-download-files {
          background-color: #474747;
        }
      }
    }


    .play-content-end {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: relative;

      .course-end-bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 88;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          opacity: 0.5;
        }

        &:after {
          content: '';
          display: block;
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          background-color: rgba(0, 0, 0, 0.9);
        }
      }

      .course-end-content {
        margin: 25px 0 0 0;
      }

      .course-end-content>*,
      h2 {
        font-family: "Poppins Bold";
        color: #fff !important;
        text-align: center;
        position: relative;
        z-index: 99;
      }
    }


    .play-content-division {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;

      @media #{$md-device} {
        min-height: calc(100vh - 55px);
      }

      .course-division-bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 88;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          opacity: 0.2;
        }

        &:after {
          content: '';
          display: block;
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          background-color: rgba(0, 0, 0, 0.9);
        }
      }

      .course-division-cnt {
        position: relative;
        z-index: 99;
        max-height: 95vh;
        overflow-y: auto;
        width: 100%;

        h2 {
          text-align: center;
        }

        .course-division-content>*,
        h2 {
          font-family: "Poppins Bold";
          color: #fff !important;
        }

        .video-player-division {
          height: 70vh !important;
          width: auto !important;
        }

        .course-division-content {
          margin: 35px auto 0 auto;
          border: 1px solid #fff;
          padding: 25px;
          border-radius: 7px;
          max-width: 1920px;
        }

        .go-to-next-btn {
          display: block;
          margin: 30px auto 0 auto;

        }
      }

    }

    .play-content-documents,
    .play-content-html {

      @media #{$md-device} {
        min-height: calc(100vh - 55px);
      }

      // box-sizing: content-box;
      padding-top: 0 !important;

      .course-bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        z-index: 88;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          opacity: 0.2;
        }

        &:after {
          content: '';
          display: block;
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          // background-color: rgba(0, 0, 0, 0.85);
        }
      }

      .documents-title {
        &:first-letter {
          text-transform: uppercase;
        }

        font-family: "Poppins Bold";
        font-size: 30px;
        margin-bottom: 5vh;
      }

      .html-wysiwig {
        img {
          max-width: 100%;
          height: auto;
        }
      }

      .go-to-next-btn {
        display: block;
        margin: 30px auto 0 auto !important;
        padding: 10px 20px;
        font-size: 25px;

        svg {
          margin-left: 10px;
        }
      }
    }

    .play-content-html {
      padding: 0;
      position: relative;

      .html-cnt {
        position: relative;
        z-index: 99;
        padding: 50px;
        background-color: rgba(0, 0, 0, 0.9);
        min-height: 100vh;

        .documents-title {
          color: #fff;
        }

        .html-wysiwig {
          background-color: #fff;
          padding: 20px;
          @include border-radius(7px);
          max-height: calc(100vh - 300px);
          overflow-y: scroll;
        }
      }
    }

    .play-content-documents {
      padding: 0;
      position: relative;

      .document-cnt {
        position: relative;
        z-index: 99;
        padding: 50px;
        background-color: rgba(0, 0, 0, 0.9);
        min-height: 100vh;

        .list-title {
          font-size: 16px;
          color: #000;
          font-weight: bold;
          margin-bottom: 20px;
          padding: 0 0 10px 0;
          border-bottom: 2px solid #838383;
        }

        .documents-title,
        .html-wysiwig {
          color: #fff;
        }

        .single-document {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          margin: 0 0 20px 0;

          &:last-child {
            margin: 0;
          }

          .download-doc {
            width: 40px;
            height: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            margin: 0 15px 0 0;
            background-color: $intact-red;
            color: #fff;
            @include transition(all 0.25s linear);

            &:hover {
              @include box-shadow(1px, 2px, 10px, 2px, rgba(82, 82, 82, 0.5));
            }
          }
        }
      }
    }


    .play-content-video {
      height: 100vh;
      overflow: hidden;

      @media #{$md-device} {
        height: auto;
        overflow: visible;
      }

      position: relative;

      .sub-cnt {
        height: 95% !important;
        max-height: calc(100vh - 175px);

        .video-title {
          &:first-letter {
            text-transform: uppercase;
          }

          font-family: "Poppins Bold";
          font-size: 30px;
          margin-bottom: 5vh;
        }

        .video-player {
          height: 100% !important;
          width: auto !important;
          padding: 0 !important;
          background-color: #000000;
        }

        .react-player__preview {
          background-size: 100px !important;
          background-repeat: no-repeat !important;
          background-position: center !important;
        }

        .react-player__play-icon {
          opacity: 0 !important;
        }
      }
    }

    .meeting-bg {
      background-blend-mode: soft-light;
      background-size: cover;
    }

    .play-meeting-reservation {
      overflow-y: auto;
      position: relative;
      @include box-sizing();
      @include transition(all 0.25s linear);
      width: calc(100vw - 350px);
      background-color: $light-blue;
      padding: 25px;

      @media #{$lg-device} {
        width: calc(100vw - 300px);
      }

      @media #{$md-device} {
        width: 100%;
        height: unset;
      }

      button {
        svg {
          margin-left: 15px;
        }
      }



      .meeting-content-calendar {
        max-width: 90% !important;
        transition: 1s;

        .calendar-bg {
          background-color: #fff;
          border: 1px solid $meeting-red;
          margin: 25px 0 0 0 !important;
        }
      }

      .meeting-content {
        transition: 1s;
        position: relative;
        z-index: 99;
        background-color: #ffffffd1;
        border-radius: 15px;
        box-shadow: 0px 0px 20px #00000080;
        padding: 20px;
        margin-bottom: 25px;
        max-width: 690px;
        font-size: 14px;

        .meeting-unsubscribe {

          position: relative;
          padding: 15px;
          margin-top: 10px;
          height: 100%;
          position: static;
          display: contents;

          .modal {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: #ffffffed;
            z-index: 88;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 15px;
          }

        }

        .meeting-icon {
          margin: 0 10px 0 0;
          float: left;
        }

        .meeting-header {
          div {
            display: inline-block;
          }

          .meeting-title {
            margin-top: 10px;
            margin-bottom: 0;
            width: 90%;
          }

          .faCalendar {
            color: $meeting-red;
          }
        }

        .meeting-header.success,
        .alert-success {
          color: #000000;

          .meeting-title,
          .faCalendar {
            color: $meeting-confirm
          }
        }

        .meeting-title {
          color: $meeting-red;
          font-size: 16px;
          line-height: 20px;
          font-weight: bold;
          margin-bottom: 10px;

          span {
            font-weight: normal;
            font-size: 15px;
          }
        }

        .meeting-subtitle {
          color: #000;
          font-size: 17px;
          line-height: 20px;
          font-weight: bolder;
          margin-bottom: 10px;
          width: 90%;
        }

        .meeting-description {
          margin-top: 15px;
          font-weight: normal;

          ul,
          ol,
          p {
            margin-top: 15px;
            font-size: inherit;
          }
        }

        .meeting-info {
          display: flex;
          flex-wrap: wrap;
          color: $meeting-grey;
          margin-top: 10px;

          div:first-child {
            width: 5%;
            text-align: center;
          }

          div:last-child {
            width: 95%;
            font-size: 13px;
          }
        }

        .meeting-message {
          background-color: $meeting-message;
          padding: 20px;
          margin: 20px 0 20px 0;
          white-space: pre-line;
        }

        .confirm {
          margin: 0 0 15px 0;

          div {
            font-weight: bold;
            color: $meeting-confirm;
            font-size: 14px;
            margin: 0 0 10px 0;
          }
        }

        .noLink {
          color: $meeting-red;
          font-size: inherit;
          font-weight: bold;
        }

        .btn-meeting {
          margin-top: 10px;
          border-color: $meeting-grey;
          background-color: $meeting-grey;
          color: #fff;
          font-size: 14px;

          &:hover {
            border-color: $meeting-red;
            background-color: $meeting-red;
            color: #fff;
          }
        }

        .btn-meeting-selected {
          // border-color: transparent !important;
          background-color: transparent !important;
          color: $meeting-red;
          border: none;
          border-radius: 0;
          border-bottom: 3px solid $meeting-red;
          pointer-events: none !important;
        }

        .btn-meeting-participe {
          background-color: $meeting-confirm ;
          color: #fff;
          font-size: 22px;

          animation: pulse 1.25s ease infinite;
          border: none;
          outline: 5px solid rgba(17, 162, 74, 1);

          &:hover {
            border-color: $meeting-red;
            background-color: $meeting-red;
            color: #fff;
            outline: 5px solid $meeting-red !important;
          }

        }

        @keyframes pulse {
          0% {
            outline: 5px solid rgba(17, 162, 74, 1);

          }

          50% {
            outline: 5px solid rgba(17, 162, 74, 0);
          }

          100% {
            outline: 5px solid rgba(17, 162, 74, 1);
          }
        }

        .intro-meeting-select-text {
          @include border-radius(5px);
          position: relative;
          color: #000;
          font-weight: bold;

          .info-circle {
            position: absolute;
            top: 10px;
            left: 10px;
            font-size: 25px;
            color: $meeting-grey;
          }
        }

        .date-selection-display-buttons-cnt {

          display: flex;
          justify-content: flex-start;
          align-items: center;

          p {
            margin: 0 25px 0 0;
            color: #fff;
          }

          .date-selection-display-buttons {
            display: flex;

            button {
              margin-right: 25px;
              font-size: 14px;

              svg {
                margin-left: 15px;
              }
            }
          }
        }

        .date-selection-select-cnt {
          .ant-select-selection-search-input {
            color: #000
          }

          .ant-select-selection-search-input:hover {
            cursor: pointer;
          }

          .date-select-dropdown {
            margin: 25px 0 25px 0 !important;
            background-color: #fff;
            border-radius: 5px;

            .ant-select-selector {
              border-color: $meeting-red 1px solid !important;
            }

            .ant-select-arrow {
              color: $meeting-red;
            }

            .ant-select-selection-placeholder {
              color: #000
            }

            .anticon-close-circle {
              vertical-align: 0;
            }

          }

          .btn {
            display: flex;
            justify-content: center;

            svg {
              margin-left: 15px;
              font-size: 20px;
            }
          }
        }

        .date-select-calendar-cnt {
          position: relative;
          padding: 15px;
          border-radius: 7px;
          margin-top: 10px;

          .rbc-event-content {
            font-size: 12px;
            white-space: normal;
          }

          .rbc-toolbar {
            font-size: 12px;

            .rbc-toolbar-label {
              font-weight: bold;
              text-transform: capitalize;
              font-size: 14px;
            }

            .rbc-btn-group:last-child {
              float: right;
            }


            .rbc-btn-group:first-child button:last-child,
            .rbc-btn-group:first-child button:nth-child(2) {
              font-family: Arial, Helvetica, sans-serif;
              font-size: 16px;
              font-weight: bolder;
            }

          }

          .date-select-calendar-modal {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.5);
            z-index: 88;
            display: flex;
            justify-content: center;
            align-items: center;

            .date-select-calendar-modal-box {
              background-color: #fff;
              @include border-radius(7px);
              padding: 25px;
              text-align: center;
              box-shadow: 0px 0px 20px #00000080;

              .date-select-calendar-modal-buttons-cnt {
                padding: 25px 15px 0 15px;
              }
            }
          }

        }

      }
    }


    .play-list-menu {
      width: 350px;
      height: 100%;
      overflow: hidden;
      display: flex;
      flex-direction: column;

      @media #{$lg-device} {
        width: 300px;
      }

      @include transition(all 0.25s linear);

      .progression {
        background-color: $avg-grey;
        padding: 30px 0 20px 0;
        position: relative;

        @media #{$sm-device} {
          padding: 0 0 10px 0;
        }

        .back-btn {
          position: absolute;
          top: 5px;
          left: 5px;
          font-size: 20px;
          display: flex;
          justify-content: center;
          align-items: center;

          @media #{$md-device} {
            top: -5px;
            left: -5px;
          }

          span {
            font-size: 13px;
            padding: 0 7px;
          }

          &:hover {
            cursor: pointer;

            .back-icon {
              color: $intact-red;
            }
          }
        }

        .hide-menu-btn {
          position: absolute;
          top: 7px;
          right: 0;
          width: 28px;
          height: 28px;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: $intact-red;
          @include border-radius-separate(5px, 0, 5px, 0);

          color: #fff;

          &:hover {
            cursor: pointer;
          }

          @media #{$md-device} {
            display: none;
          }
        }

        .search-btn {
          position: absolute;
          bottom: 5px;
          left: 5px;

          &:hover {
            color: $intact-red;
            cursor: pointer;
          }
        }

        .documents-btn {
          position: absolute;
          bottom: 5px;
          right: 5px;
          color: #ffffff;
          background-color: $intact-red;
          border-radius: 50%;
          width: 30px;
          height: 30px;
          font-size: 14px;
          display: flex;
          justify-content: center;
          align-items: center;
          box-sizing: border-box;

          &:hover {
            border: 1px solid $intact-red;
            background-color: #fff;
            color: $intact-red;
            cursor: pointer;
          }
        }

        .course-title {
          padding: 0 0 0 10px;
          text-align: center;
          margin: 0;
          font-size: 16px;
          font-weight: bold;
        }

        .progress-bar {
          width: 90%;
          height: 20px;
          margin: 0 auto 10px auto;
          background-color: $avg-grey;
          border: 1px solid #878787;
          border-radius: 10px;

          .progress-bar-inside {
            height: 100%;
            background-color: $intact-red;
          }
        }

        .progress-number {
          text-align: center;
        }
      }

      .search-input-cnt {
        width: 100%;
        z-index: 101;
        background-color: $avg-grey;
        padding: 10px;
        text-align: center;
        border-top: 1px solid #676767;

        .search-input {
          padding: 5px 10px;
          width: 90%;
          @include transition(all 0.25s linear);
          border: 1px solid $light-grey;
          @include border-radius-separate(5px, 5px, 5px, 5px);

          &:focus,
          &:hover {
            @include border-radius-separate(25px, 5px, 5px, 5px);
            outline: none;
            box-shadow: none;
          }
        }
      }

      .team-select-dropdown-cnt {
        background-color: #454545;
        padding: 10px 5px;
        font-weight: bold;

        .team-select-dropdown-title {
          color: #fff;
          text-align: center;
          margin: 0 0 7px 0;
          font-size: 16px;
          white-space: pre-wrap;
        }

        .ant-select-clear {
          top: calc(50% - 3px);
        }
      }

      ul {
        flex: 1;
        overflow-y: auto;
        background-color: $avg-grey;
        border-top: 2px solid #1b1e21;
        width: 100%;
        padding: 0;
        margin: 0;
        list-style: none;

        @media #{$md-device} {
          height: calc(100vh - 240px);
        }

        &::-webkit-scrollbar {
          width: 12px;
          background-color: #F5F5F5;
          border-left: solid 10px transparent;
          cursor: pointer;
        }

        &::-webkit-scrollbar-track {
          -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
          border-radius: 10px;
          background-color: #F5F5F5;
        }

        &::-webkit-scrollbar-thumb {
          border-radius: 10px;
          -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
          background-color: #555;

        }

        li {
          background-color: $avg-grey;
          padding: 30px 5px 30px 40px;
          display: flex;
          border-bottom: 1px solid #848484;
          align-items: center;
          position: relative;
          @include transition(all 0.25s linear);

          .list-title {
            margin: 0;
            padding: 0;
            font-size: 15px;
            width: 100%;
            @include transition(all 0.25s linear);

            .list-type-icon {
              margin: 0 5px 0 0;
            }

            .list-title-text-searched {
              background-color: yellow;
              color: #000;
            }

            .list-title-time {
              font-size: 13px;
              padding: 0 0 0 5px;
              font-weight: bold;
              letter-spacing: 0.8px;
              white-space: pre;
            }

            .list-title-prerequis-text {
              width: 200px !important;
              text-wrap: wrap;
              display: none;
              border-radius: 7px;
              padding: 10px;
              margin: 25px 0 0 0;
              color: white;
              background-color: #cd1236;
              font-weight: bold;
              @include transition(all 0.25s linear);
            }

            &:hover {
              .list-title-prerequis-text {
                display: inline-block;
              }
            }
          }

          .list-ex-result {
            border: 1px solid #1b1e21;
            border-radius: 5px;
            min-width: 120px;
            font-size: 12px;
            padding: 5px 10px;
            text-align: center;
            margin-left: 5px;
            display: flex;
            align-items: center;
            justify-content: center;


            @media only screen and (max-width: 1500px) {
              font-size: 14px;
            }

            @media #{$lg-device} {
              min-width: 60px;
            }

            .r-label {
              font-size: 11px;

              @media #{$lg-device} {
                display: none;
              }
            }

            // .score-text {
            //   .r-label {
            //     @media #{$lg-device} {
            //       display: none;
            //     }
            //   }
            // }

            .score-arrows {
              display: inline-flex;
              flex-direction: column;
              margin: 0 0 0 10px;
              font-size: 18px;

              svg {

                &:disabled,
                &[disabled] {
                  opacity: 0.5;
                }

                &:not([disabled]):hover {
                  color: $intact-red;
                }


              }
            }
          }

          .slide-number {
            position: absolute;
            top: 2px;
            left: 17px;
            font-size: 13px;
            @include translate(-50%, 0)
          }

          .done-check,
          .not-done-check,
          .current-check,
          .not-done-lock {
            position: absolute;
            @include translate(0, -50%);
            top: 50%;
            left: 10px;
            @include transition(all 0.25s linear);
          }

          .done-check {
            display: none;
          }

          .current-check {
            display: none;
          }

          .not-done-check {
            width: 15px;
            height: 15px;
            border-radius: 50%;
            border: 1px solid $titles-grey;
          }

          .not-done-lock {
            width: 15px;
            height: 15px;
            //border-radius: 50%;
            //border: 1px solid $titles-grey;
          }

          .additional-information-btn {
            position: absolute;
            bottom: 10px;
            right: 5px;
            width: 20px;
            height: 20px;
            display: flex;
            justify-content: center;
            align-items: center;


            @media #{$md-device} {
              display: none;
            }

            svg {
              @include transition(all 0.25s linear);
              color: $intact-red;
              font-size: 21px;
            }

            &:hover {
              svg {
                color: $intact-blue !important;
              }
            }
          }

          .open-note-btn {
            position: absolute;
            @include transition(all 0.25s linear);
            top: 5px;
            right: 5px;
            background-color: #444444;
            @include border-radius(5px);
            width: 20px;
            height: 20px;
            display: flex;
            justify-content: center;
            align-items: center;

            @media #{$md-device} {
              display: none;
            }

            svg {
              @include transition(all 0.25s linear);
              color: #fff;
              font-size: 10px;
            }

            &:hover {
              svg {
                color: $intact-blue !important;
              }
            }

            &.open-note-available {
              background-color: $intact-red;

              &:hover {
                svg {
                  color: $intact-blue !important;
                }
              }
            }
          }


          &.current-play-el {
            background-color: #444444 !important;
            position: relative;

            &:after {
              content: '';
              width: 5px;
              height: 100%;
              position: absolute;
              top: 0;
              left: 0;
              z-index: 99;
              background-color: $intact-red;
            }

            .slide-number {
              color: #fff;
            }

            .list-title,
            .done-check,
            .list-ex-result {
              color: #ffffff;
              opacity: 1 !important;
            }

            & .not-done-check {
              display: none;
            }

            & .current-check {
              display: block;
              width: 15px;
              height: 15px;
              border-radius: 50%;
              border: 1px solid $titles-grey;
              overflow: hidden;
              background-color: #989898;

              &:after {
                content: ' ';
                display: block;
                width: 50%;
                height: 100%;
                background-color: #fff;
              }
            }

            .open-note-btn {
              background-color: #fff;

              svg {
                color: #444444;
              }
            }
          }

          &[done=true] {
            background-color: #bababa;

            .list-title,
            .done-check {
              @include opacity(0.85);
            }

            & .done-check {
              display: block;
            }

            & .not-done-check {
              display: none;
            }

            & .current-check {
              display: none;
            }
          }

          &[done=partially] {
            & .current-check {
              display: block;
              width: 15px;
              height: 15px;
              border-radius: 50%;
              border: 1px solid $titles-grey;
              overflow: hidden;
              background-color: #989898;

              &:after {
                content: ' ';
                display: block;
                width: 50%;
                height: 100%;
                background-color: #fff;
              }
            }
          }

          &:hover {
            cursor: pointer;
            background-color: $avg-grey;

            .list-title,
            .done-check {
              @include opacity(1);
            }
          }

          &.play-el-division-meeting {
            background-color: $light-blue !important;

            &.current-play-el {
              background-color: #63dbe1 !important;
            }
          }

          &.play-el-division {
            background-color: $intact-blue;
            padding: 30px;
            text-align: center;
            font-family: "Poppins Bold";
            letter-spacing: 1.3px;

            .slide-number {
              display: none !important;
            }

            .done-check,
            .not-done-check,
            .current-check {
              display: none !important;
            }

            .open-note-btn {
              display: none !important;
            }

            .list-title {
              color: #fff;
              opacity: 1;
              font-size: 18px;

              .list-type-icon {
                display: none !important;
              }
            }

            &:hover {
              background-color: $intact-red;
            }

          }

          &.play-el-division-sub {
            background-color: #fff;
            padding: 10px 30px;
            text-align: center;
            font-family: "Poppins Bold";
            letter-spacing: 1.3px;
            border: 5px solid $intact-blue;

            .slide-number {
              display: none !important;
            }

            .done-check,
            .not-done-check,
            .current-check {
              display: none !important;
            }

            .open-note-btn {
              display: none !important;
            }

            .list-title {
              color: #000;
              opacity: 1;
              font-size: 14px;

              .list-type-icon {
                display: none !important;
              }
            }


            &:hover {
              background-color: $intact-red;
              border: 5px solid $intact-red;

              .list-title {
                color: #fff;
              }
            }

            &.current-play-el {
              border: 0;
              padding: 15px 35px;

              .list-title {
                color: #fff;
              }
            }

          }

        }
      }
    }



    .play-list-menu-mobile {
      @media #{$md-device} {
        padding: 15px 20px;
        position: absolute;
        z-index: 999;
        background-color: $avg-grey;
        width: 100vw;
        height: calc(100vh - 55px);
        top: 55px;
        left: 0;
        @include translate(-100%, 0);
        @include transition(all 0.25s);
      }

      ul {
        @media #{$md-device} {
          border-top: 2px solid $avg-grey;
          border-left: none !important;
        }

        li {
          @media #{$md-device} {
            &[done=true] {}

            &.current-play-el {}

            &:hover {}
          }
        }
      }
    }


    .play-list-menu-mobile-open {
      left: 100%;
    }


    //***********************HIDDEN MENU*************************//

    .menu-hidden {
      margin-left: -350px;
    }

    .content-wide {
      width: 100vw;
      padding-top: 30px;
    }

  }
}