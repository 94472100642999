@import "../../utils";

.chatbot-box {

    &.chatBot-is-min {
        bottom: 0;
        top: inherit;
        opacity: 0.5;
        height: calc(20% + 92px);
        width: calc(20%);
        transform: scale(0);
        transition: transform 330ms ease-in-out;
        /*
        .react-chatbot-kit-chat-header {
            border-top-left-radius: 0;
            background-color: #00b3be;
            opacity: 0.2;
        }*/
    }

    &.chatBot-is-max {
        transition: transform 330ms ease-in-out;
        opacity: 1;
    }

}

.chatbot-main-cnt {
    position: relative;

    .chatbot-extra-buttons-cnt {
        position: absolute;
        right: 15px;
        top: 15px;
        z-index: 99;
        display: flex;
        align-items: center;

        .chatbot-expand-btn,
        .chatbot-minmax-btn {
            margin: 0 15px 0 0;

            &:hover {
                cursor: pointer;
            }
        }

        .chatbot-close-btn {
            width: 20px;
            height: 20px;
            border-radius: 50%;
            padding: 5px;
            background-color: red;
            color: #fff;
            transition: all 0.25s;

            &:hover {
                cursor: pointer;
                background-color: #000;
            }
        }

    }

    &.chatBot-is-expanded {
        .react-chatbot-kit-chat-container {
            margin: auto;
            width: 900px;
            max-width: 95vw;

            .react-chatbot-kit-chat-inner-container {
                height: 80vh;

                .react-chatbot-kit-chat-message-container {
                    height: calc(100% - 92px);

                    .react-chatbot-kit-chat-bot-message {
                        width: 90%;
                    }
                }
            }
        }
    }

    .react-chatbot-kit-chat-container {
        margin: auto;
        width: 350px;
        box-shadow: -3px 3px 10px 5px rgba(75, 75, 75, 0.3);
        transition: all 0.25s;

        p,
        .ewa-rteLine {
            font-size: 0.9rem;
        }

        .react-chatbot-kit-chat-inner-container {
            transition: all 0.25s;

            .react-chatbot-kit-chat-message-container {
                overflow-y: scroll;
                overflow-x: hidden;
                transition: all 0.25s;

                .react-chatbot-kit-chat-bot-message {
                    width: 230px;
                    transition: all 0.25s;
                    background-color: #376b7e;

                    span {
                        white-space: pre-line;
                    }

                }
            }
        }

        .react-chatbot-kit-chat-btn-send:hover {
            background-color: #376b7e !important;
        }

    }

    .options {
        padding-bottom: 15px;
    }

    .options-header {
        text-align: center;
        font-size: 25px;
        font-weight: bold;
        padding: 0 !important;
        margin: 15px 0;

    }

    .options-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }

    .option-item {
        padding: 8px 15px;
        border: 1px solid #2898ec;
        border-radius: 25px;
        color: #1f91e7;
        font-size: 0.9rem;
        margin: 3px;
        box-shadow: inset 0px 0px 0px #2273c4;
        transition: all 0.5s;
        cursor: pointer;

        &.option-item-is-selected {
            box-shadow: inset 500px 0px 0px #2273c4;
            color: #fff;
        }

        &.option-item-disabled {
            pointer-events: none;
        }
    }

    .option-item:hover {
        box-shadow: inset 1000px 0px 0px #2273c4;
        color: #ffffff;
    }

    .satisfaction-container {
        display: flex;
        flex-direction: column;
        align-items: center;

        .satisfaction-feature {
            margin: 10px;
            padding: 5px 10px 10px 10px;
            border: 1px solid #3a3a3a;
            border-radius: 7px;

            .ant-rate-star-zero {
                svg {
                    color: #afafaf;
                }
            }
        }

        .satisfaction-text {
            font-size: 14px;
        }
    }


    .chatbot-answer-cnt {
        padding-top: 25px;
        padding-bottom: 25px;

        .chatbot-answer {
            background-color: rgb(55, 107, 126);
            color: #fff;
            padding: 10px;
            border-radius: 5px;

            img,
            video {
                max-width: 100%;
            }
        }
    }

    .chatbot-avatar {
        display: flex;
        flex-direction: column;
        align-items: center;

        .chatbot-avatar-icon-cnt {
            width: 45px;
            height: 45px;
            background-color: $intact-red;
            color: #fff;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 10x;
            position: relative;
            margin: 0 0 10px 0;

            // &:after {
            //     content: "";
            //     width: 10px;
            //     height: 10px;
            //     background-color: $intact-red;
            //     position: absolute;
            //     bottom: 3px;
            //     left: 3px;
            //     transition: all 0.25s linear;
            // }
        }

        p {
            font-size: 10px;
            font-weight: bold;
            margin: 0;
        }
    }
}

.chatbot-min-icon {
    svg {
        display: inherit;
        position: absolute;
        zoom: 1.4;
        color: #0098a1;
        transform: scaleX(-1);
        -moz-transform: scaleX(-1);
        -webkit-transform: scaleX(-1);
        -ms-transform: scaleX(-1);
        top: -8px;
        left: -5px;
        z-index: inherit;
        transition: none;
        animation-duration: .4s;
        animation-name: blink;
        animation-iteration-count: 2;
    }

    @keyframes blink {
        0% {
            opacity: 1;
        }

        40% {
            opacity: 0;
        }

        100% {
            opacity: 1;
        }
    }
}